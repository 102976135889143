// import { Button } from 'antd';
// import clsx from 'clsx';
// import React from 'react';

// import '@/styles/override.css';

// import Link from '@/components/core/Link';

// interface ButtonProps {
//   label?: string;
//   height?: number;
//   size?: 'small' | 'medium' | 'large' | any;
//   type?: 'secondary' | 'ghost' | any;
//   onClick?: (e?: any) => void;
//   className?: string;
//   disableClassName?: string;
//   href?: string;
//   htmlType?: 'submit' | 'button' | 'reset';
//   loading?: boolean;
//   disabled?: boolean;
//   icon?: React.ReactNode;
//   children?: React.ReactNode;
// }

// // const Height = {
// //   small: 6,
// //   medium: 9,
// //   large: 12,
// // } as const;

// // const Border = {
// //   small: 'md',
// //   medium: 'lg',
// //   large: 'xl',
// // } as const;

// const ButtonCore: React.FC<ButtonProps> = ({
//   label,
//   onClick,
//   type = 'primary',
//   className = '',
//   size = 'middle',
//   // styles = {},
//   href = '',
//   // htmlType = 'button',
//   loading = false,
//   disabled = false,
//   icon = null,
//   children = null,
//   disableClassName = '',
//   ...props
// }) => {
//   // const heightCss = `h-${Height[size]}`;
//   // const borderCss = `rounded-${Border[size]}`;

//   const baseStyles = {
//     'text-base': true,
//     'font-bold': true,
//     [`px-2`]: true,
//     // [heightCss]: true,
//     // [borderCss]: true,
//   };

//   const sizeStyles = {
//     'small-btn': size === 'small',
//     'middle-btn': size === 'middle',
//     'large-btn': size === 'large',
//   };

//   const primaryStyles = {
//     // 'bg-pri-main': type === 'primary',
//     // 'hover:!bg-pri-lightest': type === 'primary',
//     // 'active:!bg-pri-darkest': type === 'primary',
//     // '!text-pri-darkest': type === 'primary',
//     'primary-btn': type === 'primary',
//   };

//   const secondaryStyles = {
//     'border-[1px]': type === 'secondary',
//     'border-solid': type === 'secondary',
//     'border-pri-main': type === 'secondary',
//     'hover:border-pri-darkest': type === 'secondary',
//     'active:border-pri-darkest': type === 'secondary',
//     'text-pri-darkest': type === 'secondary',
//     'secondary-btn': type === 'secondary',
//   };

//   const ghostStyles = {
//     'hover:bg-pri-lightest': type === 'ghost',
//     'active:border-pri-darkest': type === 'ghost',
//     'active:bg-pri-lightest': type === 'ghost',
//     'text-pri-darkest': type === 'ghost',
//   };

//   const combinedStyles = clsx(
//     baseStyles,
//     primaryStyles,
//     sizeStyles,
//     secondaryStyles,
//     ghostStyles
//   );

//   if (href) {
//     return (
//       <Link href={href}>
//         <Button
//           className={clsx(
//             '!flex flex-row items-center justify-center',
//             combinedStyles,
//             className
//           )}
//           type={type}
//           size={size}
//           disabled={disabled}
//           onClick={onClick}
//           loading={loading}
//           {...props}
//         >
//           {icon}
//           {label}
//           {children}
//         </Button>
//       </Link>
//     );
//   }

//   return (
//     <Button
//       className={clsx(
//         '!flex flex-row items-center justify-center',
//         combinedStyles,
//         className,
//         disabled && disableClassName
//       )}
//       type={type}
//       size={size}
//       disabled={disabled}
//       onClick={onClick}
//       loading={loading}
//       {...props}
//     >
//       {icon}
//       {label}
//       {children}
//     </Button>
//   );
// };

// export default ButtonCore;

'use client';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React, { CSSProperties } from 'react';

interface ButtonProps {
  label?: React.ReactNode;
  height?: number;
  size?: 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'ghost' | 'text' | 'link';
  onClick?: (e?: any) => void;
  className?: string;
  styles?: CSSProperties;
  href?: string;
  htmlType?: 'submit' | 'button' | 'reset';
  loading?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  disableClassName?: string;
}

const Height = {
  small: 6,
  medium: 9,
  large: 12,
} as const;

const Border = {
  small: 'md',
  medium: 'lg',
  large: 'xl',
} as const;

const ButtonCore: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = 'primary',
  size = 'medium',
  className = '',
  styles = {},
  href = '',
  htmlType = 'button',
  loading = false,
  disabled = false,
  icon = null,
  children = null,
  disableClassName = '',
}) => {
  const router = useRouter();
  const heightCss = `h-${Height[size]}`;
  const borderCss = `rounded-${Border[size]}`;

  const baseStyles = {
    'text-base transition': true,
    'font-bold': false,
    [`px-2`]: true,
    [heightCss]: true,
    [borderCss]: true,
  };

  const primaryStyles = {
    'primary-btn bg-pri-main': type === 'primary',
    'text-pri-darkest': type === 'primary',
    'active:text-white': type === 'primary',
    'md:active:bg-pri-lightest': type === 'primary',
    'md:hover:bg-pri-darkest': type === 'primary',
    'md:active:text-pri-darkest': type === 'primary',
    'md:hover:text-white': type === 'primary',
  };

  const secondaryStyles = {
    'border-[1px]': type === 'secondary',
    'border-solid': type === 'secondary',
    'border-pri-main': type === 'secondary',
    'md:hover:border-pri-darkest': type === 'secondary',
    'active:border-pri-darkest': type === 'secondary',
    'text-pri-darkest': type === 'secondary',
  };

  const ghostStyles = {
    'md:hover:bg-pri-lightest': type === 'ghost',
    'active:border-pri-darkest': type === 'ghost',
    'active:bg-pri-lightest': type === 'ghost',
    'text-pri-darkest': type === 'ghost',
  };
  const textStyles = {
    'md:hover:bg-pri-lightest': type === 'text',
    'active:border-pri-darkest': type === 'text',
    'active:bg-pri-lightest': type === 'text',
    'text-pri-darkest': type === 'text',
  };
  const linkStyles = {
    'md:hover:bg-pri-lightest': type === 'link',
    'active:border-pri-darkest': type === 'link',
    'active:bg-pri-lightest': type === 'link',
    'text-[#2A72FF] underline': type === 'link',
  };
  const isDisabled = disabled || loading;
  const disableStyles = {
    '!cursor-not-allowed brightness-75 !pointer-events-none':
      isDisabled && !disableClassName,
    disableClassName,
  };

  const combinedStyles = clsx(
    baseStyles,
    primaryStyles,
    secondaryStyles,
    ghostStyles,
    textStyles,
    linkStyles,
    disableStyles
  );

  if (href) {
    return (
      <div
        className={clsx(
          'flex flex-row items-center justify-center',
          combinedStyles,
          className
        )}
        style={styles}
        onClick={(e) => {
          if (onClick) onClick(e);
          router.push(href);
        }}
      >
        {loading && (
          <svg
            className='-ml-1 mr-3 h-5 w-5 animate-spin text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        )}
        {icon}
        {label}
        {children}
      </div>
    );
  }

  return (
    <button
      className={clsx(
        'flex flex-row items-center justify-center',
        combinedStyles,
        className
      )}
      style={styles}
      type={htmlType}
      disabled={disabled || loading}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      {loading && (
        <svg
          className='-ml-1 mr-3 h-5 w-5 animate-spin'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
        >
          <circle
            className='opacity-25'
            cx='12'
            cy='12'
            r='10'
            stroke='currentColor'
            strokeWidth='4'
          ></circle>
          <path
            className='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          ></path>
        </svg>
      )}
      {icon}
      {label}
      {children}
    </button>
  );
};

export default ButtonCore;
