'use client'; // Error components must be Client Components

import * as React from 'react';

import ButtonCore from '@/components/core/Button';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return (
    <main>
      <main className='bg-[#EDF3F1]'>
        <section className='bg-white'>
          <div className='layout flex min-h-[50vh] flex-col items-center justify-center text-center text-black'>
            <img src='/img/5xx_http.png' alt='500' />
            <h1 className='mt-8 text-4xl md:text-6xl'>Hệ thống đang bận</h1>
            <ButtonCore href='/' className='mt-6' label='Về trang chủ' />
          </div>
        </section>
      </main>
    </main>
  );
}
